.mobile-view {
    display: none;
}

.web-view {
    display: block;
}

@media (max-width: 768px) {
    .mobile-view {
        display: block;
    }
    .web-view {
        display: none;
    }
    .navbar{
        flex-wrap: nowrap!important;
    }
    .navbar-toggler {
        display: none;
    }
    #intro {
        padding-top: 10px;
        padding-bottom: 50px;
    }
    #intro h1 {
        font: normal normal bold 22px/40px Proxima Nova;
    }
    .btn-intro {
        /* margin-left: 15px; */
        font: normal normal 600 16px/20px Proxima Nova !important;
        width: 250px;
        height: 40px;
    }
    .btn-outline-primary .worker-white, .btn-outline-primary .creator-white {
        top: 9px;
        left: 21px;
    }
    #search h2 {
        margin-top: 32px;
        margin-bottom: 12px;
        font: normal normal bold 22px/28px Proxima Nova;
    }
    #search .bottom {
        margin-top: 26px;
    }
    .card-form-search {
        padding: 25px 25px;
    }
    .heading {
        margin-top: 50px;
    }
    #register h2 {
        font: normal normal bold 23px/37px Arial;
    }
    .bg-register {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important;
        background-image: unset;
    }
    .bg-testimonials {
        background-image: unset;
    }
    .testimonials {
        padding-top: 30px;
    }
    #footer {
        padding-top: 22px;
        margin-top: 25px;
    }
    .m-center {
        text-align: center;
    }
    .card-how-it-works-main {
        margin-top: unset;
    }
    .card-how-it-works-main h2 {
        padding-top: 12px;
        padding-bottom: 12px;
        font: normal normal bold 22px/32px Proxima Nova;
    }
    .card-how-it-works {
        padding: 20px;
        padding-bottom: 8px;
        margin-bottom: 24px;
    }
    .card-how-it-works .bottom {
        margin-top: 5px;
    }
    .card-how-it-works h3 {
        font: normal normal 600 16px/16px Proxima Nova;
    }
    .card-how-it-works .btn-work {
        height: 32px !important;
        width: 32px !important;
    }
    .card-how-it-works .btn-primary.btn-work {
        padding: unset !important
    }
    .single .img-fluid {
        max-width: 55%;
    }
    .card-how-it-works-main .single h3 {
        font: normal normal bold 18px/35px Proxima Nova;
    }
    .navbar {
        justify-content: unset !important;
    }
    .navbar-brand .mobile-view img {
        margin-left: 30px;
    }
    .breadcumb h1 {
        font: normal normal bold 24px/28px Proxima Nova;
    }
    .heading h2, .heading-2 h2 {
        font: normal normal bold 22px/40px Proxima Nova;
    }
    .heading-2 {
        padding-top: 16px;
    }
}

@media (max-width: 400px) {
    .card-contact-inner{
        padding-left:unset;
        padding-right:unset;
    }
}

@media (min-width:1200px) {
    .margin-left{
        margin-left: 15px;
    }
}

@media (min-width: 535px) and (max-width:768px) {
    .margin-left{
        margin-left: 5px;
    }
}