@font-face {
    font-family: Proxima Nova;
    src: url(../fonts/proxima-nova-font.otf);
}

@font-face {
    font-family: 'Proxima Nova Bold';
    src: url(../fonts/proxima-nova-font-bold.otf);
}

@font-face {
    font-family: 'PT Serif';
    src: url(../fonts/PTSerif-Bold.ttf);
}

body {
    font-family: Proxima Nova !important;
    padding: 0;
    margin: 0;
    /* background: #F9F9FD !important; */
}

a:hover {
    text-decoration: unset !important;
}

img {
    max-width: 100%;
}

.header .menu-icon {
    width: 32px;
}

.MuiTypography-body1 {
    font: 600 16px/20px Proxima Nova !important;
    color: #374957;
}

.MuiListItemIcon-root {
    min-width: 42px !important;
}

.btn-primary {
    padding: 10px !important;
    background-color: #F94D1D !important;
    border-color: #F94D1D !important;
}

.btn-primary.focus, .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem #FDAC50 !important;
}

.color-primary {
    color: #F94D1D !important;
}

form label {
    font: 500 16px/22px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
    opacity: 1;
}

form .form-control::placeholder, .css-1wa3eu0-placeholder {
    line-height: 22px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    letter-spacing: 0px;
    color: #3F455A !important;
    opacity: 0.6;
}

.form-control:focus {
    border-color: #FDAC50 !important;
    box-shadow: 0 0 0 0.2rem rgb(253 172 80 / 25%) !important;
}

.card-form-search .btn-primary {
    font: 500 18px/22px Proxima Nova;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 0.75 !important;
}

.navbar-brand img {
    min-height: 63px;
}

#opening .btn-primary {
    font: 500 18px/22px Proxima Nova;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 238px !important;
}

#register .btn-primary {
    font: 500 18px/22px Proxima Nova;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 220px !important;
}

input {
    /* padding: 20px 15px 20px 15px !important; */
}

.form-control, .css-yk16xz-control {
    border: 1px solid #324460 !important;
    border-radius: 8px !important;
}

.css-yk16xz-control, .css-g1d714-ValueContainer, .css-b8ldur-Input, #react-select-3-input {
    height: calc(1.5em + .75rem + 2px);
}

.css-1uccc91-singleValue {
    top: 50% !important;
}

.css-1hb7zxy-IndicatorsContainer {
    display: none !important;
}

.navbar-light .navbar-nav .nav-link {
    margin-left: 30px;
    font: 500 16px/20px Proxima Nova;
    color: #3F455A !important;
    text-transform: uppercase;
    opacity: 0.8;
}

.navbar .MuiButtonBase-root {
    color: #3F455A !important;
}

.navbar {
    padding: unset !important;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    border-bottom: 2px solid #F94D1D;
    font: 600 16px/20px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
    text-transform: uppercase;
    opacity: unset;
}

.h-82 {
    height: 82% !important;
}

.heading {
    margin-top: 70px;
    margin-bottom: 10px;
}

.heading-2 {
    padding-top: 70px;
    margin-bottom: 10px;
}

.heading h2, .heading-2 h2 {
    font-size: 40px;
    line-height: 50px;
    font-weight: bold;
    font-family: Proxima Nova Bold;
    letter-spacing: 0px;
    color: #3F455A;
}

.heading h2:after {
    padding-top: 8px;
    margin: auto;
    content: "" !important;
    display: block !important;
    border-bottom: 3px solid #324460;
    width: 98px !important;
}

.heading p {
    font: 500 18px/22px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
    opacity: 0.75;
}

.heading-2 p {
    font: 19px/29px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
    opacity: 0.8;
}

.heading h2 span, .heading-2 h2 span {
    color: #F94D1D;
}

#intro {
    padding-top: 100px;
    padding-bottom: 100px;
}

.btn-intro {
    font: 600 18px/22px Proxima Nova !important;
    letter-spacing: 0px !important;
    color: #3F455A !important;
    text-transform: capitalize !important;
    opacity: 0.8 !important;
    border-radius: 7px !important;
    margin-top: 15px;
    width: 250px;
    height: 54px;
}

#intro h1 {
    text-transform: capitalize;
    font-size: 60px;
    line-height: 75px;
    font-family: Proxima Nova Bold;
    color: #3F455A;
}

#intro p {
    font: normal 18px/30px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
    opacity: 0.8;
}

#search {
    background: transparent linear-gradient(151deg, #FDAF4E 0%, #F78E5F 47%, #F06A73 100%) 0% 0% no-repeat padding-box;
}

#search .bg-search {
    background-image: url('../images/bg-search.svg');
    background-repeat: no-repeat;
    background-position: top 45px right 25px;
}

#search .image-left {
    margin-top: -30px;
    left: 90px;
    z-index: 1;
}

#search .image-right {
    right: 40px;
    z-index: 1;
}

#search h2 {
    margin-top: 50px;
    margin-bottom: 50px;
    font: bold 30px/45px Proxima Nova;
    letter-spacing: 0.9px;
    color: #FFFFFF;
    text-transform: capitalize;
}

#search .bottom {
    margin-top: 70px;
}

#register {
    background: transparent linear-gradient(180deg, #fdaf4e38 0%, #f78e5f33 47%, #f06a7330 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #B5B5B529;
    /* opacity: 0.17; */
}

#register h2 {
    font: bold 36px/60px Arial;
    letter-spacing: 0px;
    color: #3F455A;
}

.bg-register {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    background-image: url('../images/bg-register.svg');
    background-repeat: no-repeat;
    background-position: bottom 0px right 0px;
}

.card-form-search {
    padding: 50px 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #151C1D12;
}

.btn-outline-primary {
    position: relative;
    display: inline-block;
}

.btn-outline-primary {
    font: 600 18px/22px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A !important;
    text-transform: capitalize;
    opacity: 0.8;
    border-color: #F94D1D !important;
}

.btn-outline-primary:hover {
    color: #FFF !important;
    border-color: #F94D1D !important;
    background-color: #F94D1D !important;
}

.btn-outline-primary .worker-white, .btn-outline-primary .creator-white {
    display: none;
    position: absolute;
    top: 16px;
    left: 27px;
    z-index: 99;
}

.btn-outline-primary:hover .worker-white, .btn-outline-primary:hover .creator-white {
    display: inline;
}

#footer {
    background: #37474F 0% 0% no-repeat padding-box;
    padding-top: 50px;
    padding-bottom: 50px;
}

#footer p {
    margin-top: 20px;
    text-align: center;
    font: normal 14px/25px Proxima Nova;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.8;
}

#footer ul {
    padding-left: unset;
    list-style: none;
}

#footer h5 {
    font: 500 18px/53px Proxima Nova;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
}

#footer h6 {
    font: 14px/24px Proxima Nova;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 0.8;
    cursor: pointer;
}

.newsletter {
    /* width: 300px; */
    position: relative;
}

.newsletter input[type="text"] {
    padding-left: 20px !important;
    height: 45px !important;
    border-radius: 48px !important;
    outline: none;
}

.newsletter button[type="submit"] {
    padding-left: 20px !important;
    padding-right: 20px !important;
    height: 41px !important;
    border-radius: 48px !important;
    position: absolute;
    top: 2px;
    border-radius: 5px;
    right: 5px;
    z-index: 2;
    border: none;
    cursor: pointer;
    background-color: #F94D1D;
    transform: translateX(2px);
    font: 500 16px/22px Proxima Nova;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    text-transform: capitalize;
}

#footer .btn-social {
    padding-top: 8px !important;
    height: 40px;
    width: 40px;
    margin-right: 15px;
    border: 1px solid #FFFFFF !important;
    border-radius: 8px !important;
    background-color: transparent !important;
}

.btn-social {
    padding-top: 8px !important;
    height: 40px !important;
    width: 40px !important;
    margin-right: 15px !important;
    border: 1px solid #757575 !important;
    border-radius: 8px !important;
    background-color: transparent !important;
}

.card-categories {
    margin-top: 30px;
    padding: 22px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #F64E2B;
    border-radius: 8px;
}

.card-categories h3 {
    margin-top: 20px;
    font: 500 22px/27px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
}

.card-categories h3.inner {
    margin-top: unset;
}

.card-categories p {
    font: 500 14px/17px Proxima Nova;
    letter-spacing: 0px;
    color: #F94D1D;
    margin-bottom: unset;
}

.card-categories a {
    display: none;
    margin-top: 25px;
    font: 500 20px/24px Proxima Nova;
    letter-spacing: 0px;
    color: #F94D1D;
}

.card-categories:hover a {
    display: block;
    color: #F94D1D;
}

.card-categories a svg {
    font: 500 18px/24px Proxima Nova !important;
    color: #F94D1D !important;
}

.card-categories:hover {
    transition: 0.3s;
}

.card-categories .btn-icon {
    cursor: unset !important;
    height: 45px !important;
    width: 45px !important;
    border-radius: 25px !important;
    background-color: #FFEDE8 !important;
    border: unset !important;
}

.card-categories svg, .card-categories button {
    color: #F94D1D;
}

.card-categories:hover {
    background: #FFEDE8 0% 0% no-repeat padding-box;
    border: unset;
}

.card-categories:hover p {
    color: #3F455A;
    opacity: 0.7;
}

.card-categories:hover .btn-icon {
    background-color: #F94D1D !important;
}

.card-categories:hover svg, .card-categories:hover button {
    color: #FFFFFF;
}

.card-job {
    margin-top: 30px;
    padding: 22px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: -3px 10px 40px #3B7AD519;
    border-radius: 8px;
}

.card-job h3 {
    font: 600 22px/27px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
    /* text-transform: capitalize; */
}

.card-job p {
    font: 500 14px/17px Proxima Nova;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    opacity: 0.5;
}

.card-job .info p {
    font: 500 16px/20px Proxima Nova;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    opacity: 0.7;
}

.card-job .info svg {
    color: #F94D1D;
}

.card-job .price {
    margin-top: 40px;
    margin-bottom: 5px;
}

.card-job .price p {
    font: bold 20px/24px Proxima Nova;
    letter-spacing: 0px;
    color: #324460 !important;
    opacity: 1;
    margin-bottom: unset !important;
}

.card-job .price a {
    margin-bottom: unset !important;
    font: 600 14px/17px Proxima Nova;
    letter-spacing: 0px;
    color: #F94D1D;
}

.card-job .MuiChip-label {
    font-size: 11px !important;
}

.badge-category {
    font: 600 8px/12px Proxima Nova;
    letter-spacing: 0px;
    color: #F94D1D !important;
    background: #FEE4DD 0% 0% no-repeat padding-box !important;
    border-radius: 2px !important;
    margin-bottom: 25px;
    text-transform: uppercase;
}

#testimonials {
    background-image: url('../images/bg-testimonials.svg');
    background-repeat: no-repeat;
    background-position: left 10px top 25px;
}

#testimonials .bottom {
    margin-top: -100px;
    margin-bottom: -8px;
}

.bg-testimonials {
    background-position: center top 110px;
    background-image: url('../images/bg-single-testimonials.png');
    background-repeat: no-repeat;
}

.testimonials {
    padding-top: 180px;
}

.testimonials-profile-picture {
    border: 6px solid #F58D65;
    background-size: cover;
    border-radius: 100px;
    width: 125px;
    height: 125px;
}

.testimonials svg {
    /* margin-top: 30px; */
    color: #F7BA16;
}

.testimonials svg.MuiSvgIcon-root {
    color: unset;
}

.testimonials h3 {
    font: 600 24px/53px Proxima Nova;
    letter-spacing: 0.48px;
    color: #000000;
    text-transform: capitalize;
    opacity: 0.8;
}

.testimonials p {
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 30px;
    font: normal 18px/35px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
    opacity: 0.7;
}

.testimonials .Carousel-button-13 {
    padding: unset !important;
    height: 36px !important;
    width: 36px !important;
    border-radius: 50px;
    background-color: #FFB573 !important;
}

.testimonials .Carousel-button-13 svg.MuiSvgIcon-root {
    margin-top: unset;
    color: unset;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
    background-color: #FFB573 !important;
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgb(251 164 84 / 50%) !important;
}

.card-how-it-works-main {
    margin-top: 4vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #2E4FAA3B;
    border: 0;
    border-bottom: 10px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #F06A73, #F78E5F, #FDAF4E);
}

.card-how-it-works-main:focus {
    outline-color: unset !important;
    outline-style: unset !important;
    outline-width: unset !important;
}

.card-how-it-works-main h2 {
    padding-top: 30px;
    padding-bottom: 40px;
    text-align: center;
    font: bold 31px/46px PT Serif;
    letter-spacing: 0px;
    color: #3F455A;
    text-transform: uppercase;
    /* opacity: 0.7; */
}

.card-contact-model {
    margin-top: 4vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #2E4FAA3B;
    border: 0;
    border-bottom: 10px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #F06A73, #F78E5F, #FDAF4E);
}

.card-contact-model:focus {
    outline-color: unset !important;
    outline-style: unset !important;
    outline-width: unset !important;
}

.card-contact-model h2 {
    padding-top: 30px;
    text-align: center;
    font: bold 35px/46px Proxima Nova bold;
    letter-spacing: 0px;
    color: #3F455A;
    text-transform: uppercase;
}

.card-contact-model p {
    text-align: center;
    padding-bottom: 40px;
    font: 500 18px/22px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
    opacity: 0.75;
}

.card-how-it-works {
    cursor: pointer;
    margin-bottom: 75px;
    padding: 40px 40px 25px 40px;
    background-color: #FFF6F3;
    border-radius: 15px;
}

.card-how-it-works:hover {
    background-color: #FB9477;
}

.card-how-it-works h3 {
    font: 600 20px/24px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
    text-transform: uppercase;
}

.card-how-it-works:hover h3 {
    font: 500 20px/24px Proxima Nova;
    color: #FFFFFF;
}

.card-how-it-works .bottom {
    margin-top: 30px;
}

.card-how-it-works .btn-work {
    cursor: unset !important;
    height: 45px !important;
    width: 45px !important;
    background: #455A64 0% 0% no-repeat padding-box !important;
    border-radius: 29px;
    border: unset !important;
}

.card-how-it-works svg {
    color: #fff !important;
}

.card-how-it-works:hover .btn-work {
    background: #F9F9FD 0% 0% no-repeat padding-box !important;
}

.card-how-it-works:hover svg {
    color: #455A64 !important;
}

.card-how-it-works-main .Carousel-button-13:disabled {
    background: #B9C1C5 0% 0% no-repeat padding-box !important;
    border-radius: 29px !important;
}

.card-how-it-works-main .Carousel-button-13 {
    margin-top: -130px;
    background: #455A64 0% 0% no-repeat padding-box !important;
    border-radius: 29px !important;
}

.card-how-it-works-main .single h3 {
    margin-top: 60px;
    margin-bottom: 60px;
    font: bold 24px/32px Proxima Nova Bold;
    letter-spacing: 0px;
    color: #3F455A;
    /* text-transform: capitalize; */
    opacity: 0.9;
}

.MuiBackdrop-root {
    opacity: 0.7 !important;
    background-color: #fff !important;
}

.close-icon {
    cursor: pointer;
    margin-top: 20px;
    color: #D0D0D0;
}

.card-contact-inner {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 70px;
    padding: 22px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #F64E2B;
    border-radius: 8px;
}

.card-contact-inner h3 {
    margin-top: 20px;
    font: 500 22px/27px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
}

.card-contact-inner p {
    font: 500 16px/17px Proxima Nova;
    letter-spacing: 0px;
    color: #F94D1D;
    margin-bottom: unset;
    padding-bottom: unset;
}

.card-contact-inner:hover {
    background: #FFEDE8 0% 0% no-repeat padding-box;
    border: unset;
}

.card-contact-inner:hover p {
    color: #3F455A;
    opacity: 0.7;
}

.breadcumb {
    background: transparent linear-gradient(151deg, #FDAF4E 0%, #F78E5F 47%, #F06A73 100%) 0% 0% no-repeat padding-box;
}

.breadcumb h1 {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    font: bold 36px/60px Arial;
    letter-spacing: 0px;
    color: #FFFFFF;
    /* background: transparent linear-gradient(151deg, #FDAF4E 0%, #F78E5F 47%, #F06A73 100%) 0% 0% no-repeat padding-box; */
}

.breadcumb p {
    font: normal 18px/30px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
    text-transform: capitalize;
    opacity: 0.8;
}

.content p {
    font: normal 18px/32px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
    opacity: 0.8;
}

.tandc h2, .support h2, .policy h2 {
    font-size: 35px;
    line-height: 50px;
    font-weight: bold;
    font-family: Proxima Nova Bold;
    letter-spacing: 0px;
    color: #3F455A;
}

.navbar-nav .MuiPaper-root {
    min-width: 130px;
}

.blog {
    margin-top: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: -3px 10px 40px #3b7ad519;
    border-radius: 8px;
}

.blog .content {
    padding: 22px;
}

.blog .content h2 {
    font: 600 22px/27px Proxima Nova;
    letter-spacing: 0px;
    color: #3F455A;
}

.blog .meta h4, .blog .meta p {
    all: unset;
    font-size: 16px;
    color: #374957;
}

.badge {
    margin-right: 15px;
    color: #F94D1D !important;
    background: #FEE4DD 0% 0% no-repeat padding-box !important;
    border-radius: 2px !important;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.blog-sidebar {
    margin-top: 30px;
}

.blog-sidebar h4 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.blog-sidebar .form-control {
    border-radius: 8px 0 0 8px !important;
}

.blog-sidebar .recent img {
    width: 72px;
    height: 72px;
    border-radius: 12px;
}

.blog-sidebar .recent h4, .blog-sidebar .categories h4 {
    margin-top: unset;
    margin-bottom: unset;
    font: 18px/24px Proxima Nova;
    letter-spacing: .25px;
    color: #374957;
    opacity: .8;
}

.btn-pagination {
    color: #fff !important;
    background-color: #F94D1D !important;
}

.pagination-blog button {
    margin-left: 10px;
    margin-right: 10px;
    font: normal normal 600 20px/28px Proxima Nova;
    letter-spacing: 0.28px;
    color: #374957;
}

.pagination-blog button.active {
    background: #F94D1D 0% 0% no-repeat padding-box;
    border-radius: 17px;
    opacity: 1;
    color: #fff;
}

.infinite-scroll-component {
    height: unset !important;
    overflow: unset !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #F94D1D !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #F94D1D !important;
}